@font-face {
  font-display: swap;
  font-family: 'Burlington';
  src: url(../public/assets/fonts/Burlington.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  src: url(../public/assets/fonts/Metropolis.otf) format('opentype');
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
  font: 112.5%/1.45em georgia, serif;
  scroll-behavior: smooth;
}

body {
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -moz-osx-font-smoothing: grayscale;
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-smoothing: antialiased;
  background: repeating-radial-gradient(
    circle at top left,
    #202a33,
    #222b39 20%
  );
  color: white;
  font-family: Metropolis;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-kerning: normal;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  line-height: 22px;
  margin: 0;
  min-height: 100%;
  word-wrap: break-word;
}

p,
ul,
li {
  margin: 0;
  padding: 0;
}
